import { Playthrough } from "@novel-games/shared-types";
import { doc } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { firestoreInstance, playthroughConverter } from "../services";

function usePlaythroughState(playthroughId?: string): {
  playthroughState: Playthrough | undefined;
  isLoading: boolean;
  error: Error | undefined;
} {
  const playthroughDocRef = playthroughId
    ? doc(firestoreInstance, `playthroughs/${playthroughId}`).withConverter(
        playthroughConverter,
      )
    : undefined;

  const [playthroughState, isLoading, error] =
    useDocumentData<Playthrough>(playthroughDocRef);

  return { playthroughState, isLoading, error };
}

export default usePlaythroughState;
