import CssBaseline from "@mui/material/CssBaseline";
import { CssVarsProvider } from "@mui/joy/styles";
import React from "react";
import { Routes, Route } from "react-router-dom";

import { Dashboard, Game, Games, Login, Navbar, Signup } from "./components";
import { useAuthStateObserver } from "./hooks/use-auth";

const App = () => {
  useAuthStateObserver();
  return (
    <>
      <CssBaseline />
      <CssVarsProvider defaultMode="system">
        <Navbar />
        <Routes>
          <Route path="/" element={<Games />} />
          <Route path="/games" element={<Games />} />
          <Route path="/games/:slug" element={<Game />} />
          <Route path="/games/:slug/:playthroughId" element={<Game />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
      </CssVarsProvider>
    </>
  );
};

export default App;
