import React, { useState } from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import Container from "@mui/joy/Container";
import Typography from "@mui/joy/Typography";
import { signInWithEmailAndPassword } from "firebase/auth";

import { useAuth } from "../../hooks";
import { authInstance } from "../../services";

function LoginForm() {
  const { setUser } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        authInstance,
        email,
        password,
      );
      console.log(userCredential.user); // User is signed in
      setUser(userCredential.user); // Update the user state in useAuth
    } catch (error) {
      console.error("Error signing in: ", error);
      setError("Failed to log in");
      // Handle error (e.g., show error message)
    }
  };

  return (
    <Container sx={{ maxWidth: { xs: "100%", sm: "400px" }, mx: "auto", p: 3 }}>
      <Typography level="h4" component="h1" sx={{ mb: 2, textAlign: "center" }}>
        Log In
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <Typography htmlFor="email" component="label">
          Email
        </Typography>
        <Input
          required
          fullWidth
          id="email"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!error}
        />
        <Typography htmlFor="password" component="label">
          Password
        </Typography>
        <Input
          required
          fullWidth
          name="password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!!error}
        />
        {error && <div>{error}</div>}
        <Button type="submit" fullWidth variant="solid" sx={{ mt: 3, mb: 2 }}>
          Sign In
        </Button>
      </Box>
    </Container>
  );
}

export default LoginForm;
