import React, { useMemo } from "react";
import CytoscapeComponent from "react-cytoscapejs";
import { type GameMap, type Player } from "@novel-games/shared-types";

interface GameMapProps {
  map: GameMap;
  player: Player;
}

// Custom function to format labels
const formatLabel = (str: string): string => {
  const parts = str.split(/(?=[A-Z0-9])/);
  return parts
    .map((part) => {
      if (/^\d+$/.test(part)) {
        return part;
      }
      return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
    })
    .join(" ");
};

const calculatePosition = (index: number, totalNodes: number) => {
  const angleStep = (2 * Math.PI) / totalNodes;
  const angle = Math.PI + index * angleStep; // Start at the bottom and spread out in a circle
  const radius = 200;
  const x = Math.cos(angle) * radius + 250;
  const y = Math.sin(angle) * radius + 250;
  return { x, y };
};

const GameMapFlow: React.FC<GameMapProps> = ({ map, player }) => {
  const elements = useMemo(() => {
    const totalNodes = player.locationsKnown.length;

    const nodes = player.locationsKnown.map((locationName, index) => {
      const location = map.locations[locationName];
      let activeThreats: string[] = [];

      if (locationName === player.location) {
        // Show all active threats in the current location
        activeThreats = Object.entries(location.threats)
          .filter(([_, threat]) => threat.status === "active")
          .map(([threatId, _]) => formatLabel(threatId));
      } else {
        // For other locations, only show threats that the player knows about
        activeThreats = Object.entries(player.threatsKnown)
          .filter(([threatId, knownLocation]) => knownLocation === locationName)
          .map(([threatId, _]) => formatLabel(threatId));
      }

      return {
        data: {
          id: locationName,
          label: formatLabel(locationName),
          current: locationName === player.location,
          threats: activeThreats,
        },
        position: calculatePosition(index, totalNodes),
      };
    });

    const edgeSet = new Set<string>();
    const edges = map.edges
      .filter((edge) => {
        const fromVisited = player.locationsVisited.includes(edge.from);
        const toVisited = player.locationsVisited.includes(edge.to);
        const fromKnown = player.locationsKnown.includes(edge.from);
        const toKnown = player.locationsKnown.includes(edge.to);
        return (
          fromVisited ||
          toVisited ||
          (fromKnown && edge.from === player.location) ||
          (toKnown && edge.to === player.location)
        );
      })
      .map((edge) => {
        const edgeId = [edge.from, edge.to].sort().join("-");
        const knownOrVisibleBlockingObstacles = edge.obstacles
          .filter(
            (obstacleId) =>
              map.obstacles[obstacleId].status === "blocking" &&
              (map.obstacles[obstacleId].visible === true ||
                player.obstaclesKnown.includes(obstacleId))
          )
          .map((obstacleId) => obstacleId);
        if (!edgeSet.has(edgeId)) {
          edgeSet.add(edgeId);
          return {
            data: {
              id: edgeId,
              source: edge.from,
              target: edge.to,
              blocked: knownOrVisibleBlockingObstacles.length > 0,
              obstacles: knownOrVisibleBlockingObstacles,
            },
          };
        }
        return null;
      })
      .filter(Boolean);

    return [...nodes, ...edges];
  }, [map, player]);

  const layout = {
    name: "preset", // Use the preset layout for deterministic positioning
  };

  const stylesheet = [
    {
      selector: "node",
      style: {
        "background-color": "#666",
        label: (ele: any) => {
          const threats: string[] = ele.data("threats");
          const locationLabel = ele.data("label");
          if (threats && threats.length > 0) {
            const threatLabel = threats.join(", ");
            return `${locationLabel}\n\n⚔️ ${threatLabel}`;
          }
          return locationLabel;
        },
        "text-valign": "center",
        "text-halign": "center",
        color: "#fff",
        "text-wrap": "wrap",
        "text-max-width": "120px", // Increased to accommodate threats
        "font-size": "12px",
        width: "label",
        height: "label",
        padding: "10px",
        shape: "rectangle",
      },
    },
    {
      selector: "node[?current]", // Style for the current location
      style: {
        "background-color": "#0b6bcb",
      },
    },
    {
      selector: "edge",
      style: {
        width: 2,
        "line-color": "#ccc",
        "target-arrow-color": "#ccc",
        "source-arrow-color": "#ccc",
        "target-arrow-shape": "triangle",
        "source-arrow-shape": "triangle",
        "curve-style": "bezier",
        "arrow-scale": 0.8,
      },
    },
    {
      selector: "edge[?blocked]",
      style: {
        label: (ele: any) => {
          const obstacles: string[] = ele.data("obstacles");
          if (obstacles && obstacles.length > 0) {
            const obstacleNames = obstacles
              .map((obstacleId: string) => formatLabel(obstacleId))
              .join(", ");
            return `🚫 ${obstacleNames}`;
          }
          return "🚫";
        },
        "font-size": "12px",
        "text-rotation": "autorotate",
        "line-color": "red",
        "target-arrow-color": "red",
        "source-arrow-color": "red",
        "text-margin-y": -10,
      },
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <CytoscapeComponent
        elements={elements}
        layout={layout}
        stylesheet={stylesheet}
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
};

export default GameMapFlow;
