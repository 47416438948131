// in hooks/useAudioRecorder.ts

import { useState, useCallback, useRef } from "react";
import axios from "axios";

interface UseAudioRecorderResult {
  isRecording: boolean;
  startRecording: () => void;
  stopRecording: () => Promise<void>;
  transcribedText: string;
}

function useAudioRecorder(): UseAudioRecorderResult {
  const [isRecording, setIsRecording] = useState(false);
  const [transcribedText, setTranscribedText] = useState("");
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const audioChunks = useRef<Blob[]>([]);

  const startRecording = useCallback(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const recorder = new MediaRecorder(stream);
        mediaRecorder.current = recorder;
        audioChunks.current = [];

        recorder.addEventListener("dataavailable", (event) => {
          audioChunks.current.push(event.data);
        });

        recorder.start();
        setIsRecording(true);
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
      });
  }, []);

  const stopRecording = useCallback(async () => {
    return new Promise<void>((resolve) => {
      if (mediaRecorder.current && isRecording) {
        mediaRecorder.current.addEventListener("stop", async () => {
          const audioBlob = new Blob(audioChunks.current, {
            type: "audio/webm",
          });
          const arrayBuffer = await audioBlob.arrayBuffer();
          const audioContext = new AudioContext();
          const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

          // Remove silence
          const processedBuffer = removeSilence(audioBuffer);

          // Convert processed buffer to WAV
          const processedBlob = audioBufferToWav(processedBuffer);

          const reader = new FileReader();
          reader.readAsDataURL(processedBlob);
          reader.onloadend = async () => {
            const base64Audio = reader.result as string;
            try {
              const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/whisper`,
                { file: base64Audio, model: "whisper-1" },
                { headers: { "Content-Type": "application/json" } }
              );
              setTranscribedText(response.data.text);
            } catch (error) {
              console.error("Error transcribing audio:", error);
            }
            setIsRecording(false);
            resolve();
          };
        });
        mediaRecorder.current.stop();
      } else {
        setIsRecording(false);
        resolve();
      }
    });
  }, [isRecording]);

  return { isRecording, startRecording, stopRecording, transcribedText };
}

function removeSilence(
  audioBuffer: AudioBuffer,
  silenceThreshold = 0.01,
  minSilenceDuration = 0.2
): AudioBuffer {
  const channelData = audioBuffer.getChannelData(0);
  const sampleRate = audioBuffer.sampleRate;
  const silenceSamples = Math.floor(minSilenceDuration * sampleRate);

  let start = 0;
  let end = channelData.length - 1;

  while (
    start < channelData.length &&
    Math.abs(channelData[start]) < silenceThreshold
  ) {
    start++;
  }

  while (end > start && Math.abs(channelData[end]) < silenceThreshold) {
    end--;
  }

  if (end - start < silenceSamples) {
    start = 0;
    end = channelData.length - 1;
  }

  const newBuffer = new AudioContext().createBuffer(
    audioBuffer.numberOfChannels,
    end - start + 1,
    sampleRate
  );

  for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
    const newChannelData = newBuffer.getChannelData(channel);
    const originalChannelData = audioBuffer.getChannelData(channel);
    for (let i = 0; i < newBuffer.length; i++) {
      newChannelData[i] = originalChannelData[start + i];
    }
  }

  return newBuffer;
}

function audioBufferToWav(buffer: AudioBuffer): Blob {
  const numOfChan = buffer.numberOfChannels;
  const length = buffer.length * numOfChan * 2 + 44;
  const out = new ArrayBuffer(length);
  const view = new DataView(out);
  let pos = 0;

  const setUint16 = (data: number) => {
    view.setUint16(pos, data, true);
    pos += 2;
  };

  const setUint32 = (data: number) => {
    view.setUint32(pos, data, true);
    pos += 4;
  };

  setUint32(0x46464952);
  setUint32(length - 8);
  setUint32(0x45564157);
  setUint32(0x20746d66);
  setUint32(16);
  setUint16(1);
  setUint16(numOfChan);
  setUint32(buffer.sampleRate);
  setUint32(buffer.sampleRate * 2 * numOfChan);
  setUint16(numOfChan * 2);
  setUint16(16);
  setUint32(0x61746164);
  setUint32(length - pos - 4);

  for (let i = 0; i < buffer.numberOfChannels; i++) {
    const channel = buffer.getChannelData(i);
    for (let j = 0; j < buffer.length; j++) {
      const sample = Math.max(-1, Math.min(1, channel[j]));
      const value = sample < 0 ? sample * 0x8000 : sample * 0x7fff;
      setUint16(value);
    }
  }

  return new Blob([out], { type: "audio/wav" });
}

export default useAudioRecorder;
