import { useState, useEffect } from "react";
import { doc, onSnapshot, getDoc } from "firebase/firestore";
import { firestoreInstance } from "../services/firebase";
import * as StorageService from "../services/storage";

function usePlaythroughImages(
  slug: string | undefined,
  playthroughId: string | undefined
) {
  const [images, setImages] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!slug) return;
    setIsLoading(true);

    async function fetchGameImage(slug: string) {
      try {
        const gameImageUrl = await StorageService.getGameImage(slug);
        if (gameImageUrl) setImages([gameImageUrl]);
      } catch (err) {
        console.error("Error fetching game image:", err);
        setError(
          err instanceof Error ? err : new Error("Failed to fetch game image")
        );
      } finally {
        setIsLoading(false);
      }
    }

    fetchGameImage(slug);

    if (playthroughId) {
      const playthroughRef = doc(
        firestoreInstance,
        "playthroughs",
        playthroughId
      );

      const unsubscribe = onSnapshot(
        playthroughRef,
        async (doc) => {
          if (doc.exists()) {
            try {
              let allImages: string[] = [];

              // Fetch game image
              const gameImageUrl = await StorageService.getGameImage(slug);
              if (gameImageUrl) allImages.push(gameImageUrl);

              // Fetch playthrough images
              const playthroughImages =
                await StorageService.getPlaythroughImages(playthroughId);
              allImages = [...allImages, ...playthroughImages];

              setImages(allImages);
            } catch (err) {
              console.error("Error fetching images:", err);
              setError(
                err instanceof Error ? err : new Error("Failed to fetch images")
              );
            } finally {
              setIsLoading(false);
            }
          } else {
            // If playthrough doesn't exist, we already have the game image
            setIsLoading(false);
          }
        },
        (err) => {
          console.error("Error in snapshot listener:", err);
          setError(err);
          setIsLoading(false);
        }
      );

      // Cleanup function to unsubscribe from the listener when the component unmounts
      return () => unsubscribe();
    }
  }, [slug, playthroughId]);

  return { images, isLoading, error };
}

export default usePlaythroughImages;
