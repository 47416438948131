import React, { useEffect, useState } from "react";
import { Box, Typography, Card, CardContent, Grid, Link } from "@mui/joy";
import { Link as RouterLink } from "react-router-dom";
import axios from "axios";

interface Game {
  id: string;
  title: string;
  slug: string;
  initialPrompt: string;
}

const Games: React.FC = () => {
  const [games, setGames] = useState<Game[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/games`
        );
        setGames(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch games");
        setLoading(false);
      }
    };

    fetchGames();
  }, []);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="danger">{error}</Typography>;
  }

  return (
    <Box sx={{ p: 8 }}>
      <Typography level="h1" sx={{ mb: 4 }}>
        Games
      </Typography>
      <Grid container spacing={3}>
        {games.map((game) => (
          <Grid key={game.id} xs={12} sm={6} md={4}>
            <Card variant="outlined">
              <CardContent>
                <Link
                  component={RouterLink}
                  to={`/games/${game.slug}`}
                  underline="none"
                  color="neutral"
                >
                  <Typography level="h2" sx={{ mb: 2 }}>
                    {game.title}
                  </Typography>
                </Link>
                <Typography sx={{ mb: 2 }}>Slug: {game.slug}</Typography>
                <Typography
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {game.initialPrompt}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Games;
