import { Game, Playthrough, PlaythroughData } from "@novel-games/shared-types";
import {
  type DocumentData,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

import { firestoreInstance } from ".";

const gamesPath = `/games`;

export const gameConverter: FirestoreDataConverter<Game> = {
  toFirestore(game: Game): DocumentData {
    return {
      characters: game.characters,
      map: game.map,
      narrativeVoice: game.narrativeVoice,
      owner: game.owner,
      setting: game.setting,
      slug: game.slug,
      timeLimit: game.timeLimit,
      title: game.title,
    };
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): Game {
    const data = snapshot.data();

    return {
      characters: data.characters,
      map: data.map,
      narrativeVoice: data.narrativeVoice,
      owner: data.owner,
      setting: data.setting,
      slug: data.slug,
      timeLimit: data.timeLimit,
      title: data.title,
    };
  },
};

const gameCollectionReference = collection(
  firestoreInstance,
  gamesPath
).withConverter(gameConverter);

export function getGameDocumentReference({ slug }: { slug: string }) {
  return doc(firestoreInstance, `${gamesPath}/${slug}`);
}

export async function getGame({
  slug,
}: {
  slug: string;
}): Promise<Game | undefined> {
  try {
    const gameQuery = query(gameCollectionReference, where("slug", "==", slug));
    const querySnapshot = await getDocs(gameQuery);

    if (querySnapshot.empty) {
      console.warn("Game doesn't exist", { slug });
      return undefined;
    }

    const gameDoc = querySnapshot.docs[0];
    const game: Game = gameDoc.data();

    return game;
  } catch (error) {
    console.error("Error getting document:", error);
  }
}

const playthroughsPath = `/playthroughs`;

export const playthroughConverter: FirestoreDataConverter<Playthrough> = {
  toFirestore(playthrough: Playthrough): Playthrough {
    return {
      characters: playthrough.characters,
      currentTurn: playthrough.currentTurn,
      map: playthrough.map,
      messageProcessingProgress: playthrough.messageProcessingProgress,
      narrativeVoice: playthrough.narrativeVoice,
      owner: playthrough.owner,
      players: playthrough.players,
      setting: playthrough.setting,
      slug: playthrough.slug,
      state: playthrough.state,
      timeLimit: playthrough.timeLimit,
      title: playthrough.title,
      turnState: playthrough.turnState,
      tokenUsage: playthrough.tokenUsage,
    };
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): Playthrough {
    const data = snapshot.data();

    console.log("data: ", data);

    return {
      characters: data.characters,
      currentTurn: data.currentTurn,
      map: data.map,
      messageProcessingProgress: data.messageProcessingProgress,
      narrativeVoice: data.narrativeVoice,
      owner: data.owner,
      players: data.players,
      setting: data.setting,
      slug: data.slug,
      state: data.state,
      timeLimit: data.timeLimit,
      title: data.title,
      tokenUsage: data.tokenUsage,
      turnState: data.turnState,
    };
  },
};

const playthroughCollectionReference = collection(
  firestoreInstance,
  playthroughsPath
).withConverter(playthroughConverter);

export function getPlaythroughDocumentReference({
  playthroughId,
}: {
  playthroughId: string;
}) {
  return doc(firestoreInstance, `${playthroughsPath}/${playthroughId}`);
}

export async function getPlaythroughs(): Promise<PlaythroughData[]> {
  const playthroughDataArray: PlaythroughData[] = [];
  const querySnapshot = await getDocs(playthroughCollectionReference);

  querySnapshot.forEach((doc) => {
    const playthroughData: PlaythroughData = {
      playthrough: doc.data() as Playthrough,
      playthroughId: doc.id,
    };
    playthroughDataArray.push(playthroughData);
  });

  return playthroughDataArray;
}

export async function getPlaythrough({
  playthroughId,
}: {
  playthroughId: string;
}): Promise<Playthrough | undefined> {
  const reference = getPlaythroughDocumentReference({ playthroughId });
  const snapshot = await getDoc(reference);

  if (!snapshot.exists()) {
    console.warn("Playthrough doesn't exist", { playthroughId });
    return undefined;
  }

  const playthrough: Playthrough = snapshot.data() as Playthrough; // This will now return a Game object

  console.log("Playthrough found", { playthroughId, playthrough });

  return playthrough;
}
