import { Message, Playthrough } from "@novel-games/shared-types";
import Box from "@mui/joy/Box";
import Chip from "@mui/joy/Chip";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import React, { useEffect, useRef } from "react";
import AIWriter from "react-aiwriter";

function Conversation({
  conversation,
  playerName,
}: {
  conversation: Message[];
  playerName: string;
}) {
  const endOfMessagesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [conversation]);

  return (
    <List sx={{ overflowY: "auto" }}>
      {conversation.map((msg, index) => (
        <ListItem
          key={index}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            mb: 4,
          }}
        >
          <Chip
            color={msg.role === "narrator" ? "neutral" : "primary"}
            sx={{ fontWeight: "bold", mb: 1, alignSelf: "flex-start" }}
            variant="solid"
          >
            {msg.role === "player" ? playerName : "Narrator"}
          </Chip>
          <Box sx={{ textAlign: "left" }}>
            {index != 0 && index === conversation.length - 1 ? (
              <AIWriter>{msg.message}</AIWriter>
            ) : (
              msg.message
            )}
          </Box>
        </ListItem>
      ))}
      <div ref={endOfMessagesRef} />
    </List>
  );
}

export default Conversation;
