import React, { useState } from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import Container from "@mui/joy/Container";
import Typography from "@mui/joy/Typography";
import { createUserWithEmailAndPassword } from "firebase/auth";

import { authInstance } from "../../services";

function SignUpForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        authInstance,
        email,
        password,
      );
      console.log(userCredential.user); // User is signed up
      // Redirect or update UI
    } catch (error) {
      console.error("Error signing up: ", error);
      setError("Failed to sign up");
    }
  };

  return (
    <Container sx={{ maxWidth: { xs: "100%", sm: "400px" }, mx: "auto", p: 3 }}>
      <Typography level="h4" component="h1" sx={{ mb: 2, textAlign: "center" }}>
        Sign Up
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <Typography htmlFor="email" component="label">
          Email
        </Typography>
        <Input
          required
          fullWidth
          id="email"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!error}
          placeholder="Enter your email"
        />
        <Typography htmlFor="password" component="label">
          Password
        </Typography>
        <Input
          required
          fullWidth
          name="password"
          type="password"
          id="password"
          autoComplete="new-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!!error}
          placeholder="Enter your password"
        />
        <Typography htmlFor="confirmPassword" component="label">
          Confirm Password
        </Typography>
        <Input
          required
          fullWidth
          name="confirmPassword"
          type="password"
          id="confirmPassword"
          autoComplete="new-password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          error={!!error}
          placeholder="Confirm your password"
        />
        {error && <div>{error}</div>}
        <Button type="submit" fullWidth variant="solid" sx={{ mt: 3, mb: 2 }}>
          Sign Up
        </Button>
      </Box>
    </Container>
  );
}

export default SignUpForm;
