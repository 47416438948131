import { Game } from "@novel-games/shared-types";
import { useState, useEffect } from "react";

import { getGame } from "../services";

function useGameState(slug?: string): {
  gameState: Game | undefined;
  isLoading: boolean;
  error: Error | undefined;
} {
  const [gameState, setGameState] = useState<Game | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  useEffect(() => {
    async function fetchGame() {
      if (slug) {
        try {
          setIsLoading(true);
          const game = await getGame({ slug });
          console.log("Game fetched:", game);
          setGameState(game);
          setIsLoading(false);
        } catch (err) {
          console.error("Error fetching game:", err);
          setError(err as Error);
          setIsLoading(false);
        }
      }
    }

    fetchGame();
  }, [slug]);

  return { gameState, isLoading, error };
}

export default useGameState;
