import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storageInstance } from ".";

async function getSignedUrl(filepath: string): Promise<string> {
  try {
    const fileRef = ref(storageInstance, filepath);
    const url = await getDownloadURL(fileRef);
    return url;
  } catch (error) {
    console.error(`Error generating signed URL for ${filepath}:`, error);
    return "";
  }
}

export async function getGameImage(slug: string): Promise<string> {
  return getSignedUrl(`games/${slug}/${slug}.png`);
}

export async function getPlaythroughImages(
  playthroughId: string
): Promise<string[]> {
  try {
    const folderRef = ref(storageInstance, `playthroughs/${playthroughId}`);
    const result = await listAll(folderRef);

    const urls = await Promise.all(
      result.items.map(async (itemRef) => {
        try {
          return await getDownloadURL(itemRef);
        } catch (error) {
          console.error(
            `Error getting download URL for ${itemRef.fullPath}:`,
            error
          );
          return "";
        }
      })
    );

    return urls.filter((url) => url !== ""); // Remove any failed URLs
  } catch (error) {
    console.error(
      `Error listing playthrough images for ${playthroughId}:`,
      error
    );
    return [];
  }
}
