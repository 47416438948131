import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/joy/Link";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";

import { OpenAiModel, PlaythroughData } from "@novel-games/shared-types";
import { getPlaythroughs } from "../../services";

const Dashboard = () => {
  const navigate = useNavigate();
  const [playthroughDatas, setPlaythroughDatas] = useState<PlaythroughData[]>(
    []
  );
  const tokenCosts: Record<OpenAiModel, number> = {
    "gpt-4o": 0.005 / 1000,
  };
  const calculateCost = (model: OpenAiModel, tokens: number) => {
    const costPerToken = tokenCosts[model] || 0.03 / 1000;
    return (tokens * costPerToken).toFixed(4);
  };

  useEffect(() => {
    const fetchPlaythroughs = async () => {
      const data = await getPlaythroughs();
      const sortedData = data.sort((a, b) =>
        a.playthrough.createdAt > b.playthrough.createdAt ? -1 : 1
      );
      setPlaythroughDatas(sortedData);
    };

    fetchPlaythroughs();
  }, []);

  const formatNumber = (number: number) => {
    return number.toLocaleString("en-US");
  };

  return (
    <>
      <Typography level="h1" sx={{ marginTop: "80px", padding: 1 }}>
        Playthroughs
      </Typography>
      <Table aria-label="playthroughs table" sx={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "200px" }}>ID</th>
            <th>Firestore</th>
            <th style={{ width: "50px" }}>Count</th>
            <th>Model</th>
            <th style={{ width: "100px" }}>Tokens Used</th>
            <th>Cost Estimate</th>
          </tr>
        </thead>
        <tbody>
          {playthroughDatas.map(({ playthrough, playthroughId }) => (
            <tr key={playthroughId}>
              <td>
                <Link
                  component="button"
                  onClick={() =>
                    navigate(`/games/${playthrough.slug}/${playthroughId}`)
                  }
                >
                  {playthroughId}
                </Link>
              </td>
              <td>
                <Link
                  href={`https://console.firebase.google.com/u/0/project/novelgames-production/firestore/data/playthroughs/${playthroughId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Document
                </Link>
              </td>
              <td>
                {Object.values(playthrough.players).reduce(
                  (acc, player) => acc + player.conversation.length,
                  0
                )}
              </td>
              <td style={{ whiteSpace: "pre-line" }}>
                {Object.keys(playthrough.tokenUsage || {}).join("\n")}
              </td>
              <td style={{ whiteSpace: "pre-line" }}>
                {Object.values(playthrough.tokenUsage || {}).map(
                  (tokens) => `${formatNumber(tokens)}\n`
                )}
              </td>
              <td style={{ whiteSpace: "pre-line" }}>
                {Object.entries(playthrough.tokenUsage || {}).map(
                  ([model, tokens]) =>
                    `$${calculateCost(model as OpenAiModel, tokens)}\n`
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default Dashboard;
