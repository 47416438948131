import React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";
import { Link } from "react-router-dom";
import { useAuth } from "../../hooks";

function Navbar() {
  const { user, logout } = useAuth();

  return (
    <Box
      style={{
        height: "50px",
        position: "fixed",
        top: 0,
        left: 0, // Ensure the navbar starts from the very left
        right: 0, // Ensure the navbar extends to the very right
        zIndex: 100,
      }}
      sx={{
        borderBottom: "1px solid #383535",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0.5rem 1rem",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        margin: "0 auto", // Centrally align the navbar content
        maxWidth: "calc(100% - 2rem)", // Limit the width and ensure padding
      }}
    >
      <img
        src="/novel-logo.svg"
        alt="Logo"
        style={{
          height: "25px",
          WebkitFilter: "invert(100%)", // For Safari 6.0 - 9.0
          filter: "invert(100%)", // Standard syntax
        }}
      />
      <Typography
        component={Link}
        to="/games"
        sx={{ textDecoration: "none", color: "inherit" }}
      >
        Games
      </Typography>
      {user ? (
        <Button onClick={logout}>Logout</Button>
      ) : (
        <Box>
          <Button component={Link} to="/signup" sx={{ marginRight: "0.5rem" }}>
            Signup
          </Button>
          <Button component={Link} to="/login">
            Login
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default Navbar;
