import React from "react";
import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import Checkbox from "@mui/joy/Checkbox";
import Typography from "@mui/joy/Typography";
import ErrorIcon from "@mui/icons-material/Error";
import Accordion from "@mui/joy/Accordion";
import AccordionSummary from "@mui/joy/AccordionSummary";
import AccordionDetails from "@mui/joy/AccordionDetails";
import {
  type Objective,
  type Objectives as ObjectivesType,
} from "@novel-games/shared-types";

type ObjectivesProps = {
  objectives: ObjectivesType;
};

const Objectives: React.FC<ObjectivesProps> = ({ objectives }) => {
  const sortedObjectives = Object.values(objectives).sort(
    (a, b) => a.id - b.id,
  );
  const requiredObjectives = sortedObjectives.filter((o) => o.required);
  const bonusObjectives = sortedObjectives.filter((o) => !o.required);

  const renderObjective = (objective: Objective) => {
    return (
      <Box
        color="primary"
        key={objective.description}
        sx={{ display: "flex", alignItems: "center", mb: 1 }}
      >
        {objective.status === "failed" ? (
          <ErrorIcon color="error" sx={{ mr: 1 }} />
        ) : (
          <Checkbox
            checked={objective.status === "complete"}
            disabled
            sx={{ mr: 1 }}
          />
        )}
        <Typography
          sx={{
            textDecoration:
              objective.status === "failed" ? "line-through" : "none",
          }}
        >
          {objective.description}
        </Typography>
      </Box>
    );
  };

  return (
    <Accordion>
      <AccordionSummary>
        <Typography level="h4" sx={{ p: 1 }}>
          Objectives
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Sheet
          color="primary"
          sx={{
            padding: 2,
            display: "grid",
            gridTemplateColumns: { sm: "1fr", md: "1fr 1fr" },
            gap: 1,
          }}
          variant="soft"
        >
          <Box sx={{ gridColumn: "1" }}>
            {requiredObjectives.map(renderObjective)}
          </Box>
          <Box sx={{ gridColumn: "2" }}>
            {bonusObjectives.map(renderObjective)}
          </Box>
        </Sheet>
      </AccordionDetails>
    </Accordion>
  );
};

export default Objectives;
