import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { authInstance } from "../services/firebase";
import { useEffect } from "react";

type User = {
  uid: string;
  email: string | null;
};

type State = {
  user: User | null;
  loading: boolean;
};

type Actions = {
  setUser: (user: User | null) => void;
  setLoading: (loading: boolean) => void;
  logout: () => Promise<void>;
};

const useAuth = create<State & Actions>()(
  immer((set) => ({
    user: null,
    loading: true,

    setUser: (user) => {
      set((state) => {
        state.user = user;
      });
    },

    setLoading: (loading) => {
      set((state) => {
        state.loading = loading;
      });
    },

    logout: async () => {
      try {
        await signOut(authInstance);
        set((state) => {
          state.user = null;
        });
      } catch (error) {
        console.error("Error logging out:", error);
      }
    },
  })),
);

// Auth state observer
export const useAuthStateObserver = () => {
  const setUser = useAuth((state) => state.setUser);
  const setLoading = useAuth((state) => state.setLoading);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authInstance, (user) => {
      if (user) {
        // User is signed in
        setUser({ uid: user.uid, email: user.email });
      } else {
        // User is signed out
        setUser(null);
      }
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [setUser, setLoading]);
};

export default useAuth;
