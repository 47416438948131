import React, { useEffect, useRef, useState, useCallback } from "react";
import DiceBox from "@3d-dice/dice-box-threejs";
import { Modal, Sheet, Typography, CircularProgress } from "@mui/joy";

interface DiceBoxInstance {
  updateConfig: (config: any) => void;
  roll: (notation: string) => void;
}

interface DiceRollerProps {
  rollValue: number | null; // Change this to allow null
}

const DiceRoller: React.FC<DiceRollerProps> = ({ rollValue }) => {
  const [open, setOpen] = useState(false);
  const [isContainerReady, setIsContainerReady] = useState(false);
  const [isRolling, setIsRolling] = useState(false);
  const boxRef = useRef<DiceBoxInstance | null>(null);
  const containerId = useRef(
    `dice-container-${Math.random().toString(36).substr(2, 9)}`
  );
  const previousRollValueRef = useRef<number | null>(null);
  const isInitialMount = useRef(true);

  const handleRoll = useCallback(() => {
    if (boxRef.current && rollValue !== null) {
      setIsRolling(true);
      const diceNotation = `1d20@${rollValue}`;
      boxRef.current.roll(diceNotation);
    }
  }, [rollValue]);

  const handleClose = useCallback(() => {
    setOpen(false);
    setIsContainerReady(false);
    setIsRolling(false);
    boxRef.current = null;
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      previousRollValueRef.current = rollValue;
    } else if (
      rollValue !== null &&
      rollValue !== previousRollValueRef.current
    ) {
      previousRollValueRef.current = rollValue;
      setOpen(true);
      setIsContainerReady(false);
      setIsRolling(false);
      boxRef.current = null;
    }
  }, [rollValue]);

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        setIsContainerReady(true);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [open]);

  useEffect(() => {
    if (isContainerReady && !boxRef.current && rollValue !== null) {
      const container = document.getElementById(containerId.current);
      if (container) {
        container.innerHTML = "";

        const Box = new DiceBox(`#${containerId.current}`, {
          theme_customColorset: {
            background: "#0b6bcb",
            foreground: "#ffffff",
            material: "plastic",
          },
          light_intensity: 1,
          gravity_multiplier: 300,
          baseScale: 100,
          strength: 3,
          onRollComplete: (results: any) => {
            console.log(`Roll results:`, results);
            setIsRolling(false);
            // Close the modal after a short delay
            setTimeout(() => {
              handleClose();
            }, 2000);
          },
        });
        Box.initialize()
          .then(() => {
            boxRef.current = Box as unknown as DiceBoxInstance;
            handleRoll();
          })
          .catch((e: Error) => console.error(e));
      }
    }
  }, [isContainerReady, handleRoll, handleClose, rollValue]);

  if (rollValue === null) {
    return null;
  }

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={handleClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Sheet
        variant="outlined"
        sx={{
          maxWidth: 800,
          borderRadius: "md",
          p: 3,
          boxShadow: "lg",
        }}
      >
        <Typography
          component="h2"
          id="modal-title"
          level="h4"
          textColor="inherit"
          fontWeight="lg"
          mb={1}
        >
          D20 Dice Roller
        </Typography>
        <div
          id={containerId.current}
          style={{
            width: "700px",
            height: "400px",
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "16px",
          }}
        >
          {isRolling && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CircularProgress size="sm" />
              <Typography level="body-sm" sx={{ mt: 1 }}>
                Rolling...
              </Typography>
            </div>
          )}
        </div>
      </Sheet>
    </Modal>
  );
};

export default DiceRoller;
