import React, { useState, useEffect } from "react";
import Box from "@mui/joy/Box";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";
import IconButton from "@mui/joy/IconButton";
import MicIcon from "@mui/icons-material/Mic";
import CircularProgress from "@mui/joy/CircularProgress";
import Typography from "@mui/joy/Typography";

import { useVoiceRecording } from "../../hooks";

interface MessageFormProps {
  isDisabled: boolean;
  onSend: (message: string) => void;
}

const MessageForm: React.FC<MessageFormProps> = ({ isDisabled, onSend }) => {
  const [inputText, setInputText] = useState("");
  const {
    isRecording,
    isProcessing,
    secondsLeft,
    transcribedText,
    toggleRecording,
    MAX_RECORDING_TIME,
  } = useVoiceRecording();

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (inputText.trim()) {
      onSend(inputText.trim());
      setInputText("");
    }
  };

  useEffect(() => {
    if (transcribedText) {
      setInputText((prevText) => (prevText + " " + transcribedText).trim());
    }
  }, [transcribedText]);

  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column", p: 2 }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Input
          disabled={isDisabled}
          placeholder="Type your message..."
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          fullWidth
          onKeyDown={handleKeyPress}
          endDecorator={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              {isProcessing ? (
                <CircularProgress
                  color="primary"
                  size="sm"
                  variant="soft"
                  sx={{
                    width: 36,
                    height: 36,
                  }}
                />
              ) : (
                <IconButton
                  color={isRecording ? "danger" : "primary"}
                  onClick={toggleRecording}
                  sx={{
                    width: 36,
                    height: 36,
                    borderRadius: "50%",
                    transition: "all 0.2s",
                    "&:hover": { transform: "scale(1.1)" },
                  }}
                >
                  <MicIcon fontSize="small" />
                </IconButton>
              )}
              {isRecording && (
                <CircularProgress
                  determinate
                  color="danger"
                  size="sm"
                  variant="solid"
                  value={(MAX_RECORDING_TIME - secondsLeft) * 10}
                >
                  <Typography
                    level="body-xs"
                    fontWeight="bold"
                    sx={{ position: "absolute" }}
                  >
                    {Math.ceil(secondsLeft)}
                  </Typography>
                </CircularProgress>
              )}
            </Box>
          }
          sx={{
            flexGrow: 1,
            mr: 1,
            "& textarea": {
              minHeight: "60px",
              resize: "vertical",
            },
          }}
        />
      </Box>
      <Button
        disabled={isDisabled || !inputText.trim()}
        onClick={handleSubmit}
        sx={{ mt: 2 }}
        fullWidth
      >
        Send
      </Button>
    </Box>
  );
};

export default MessageForm;
