import React from "react";
import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";
import { Game } from "@novel-games/shared-types";

type CharacterSelectionProps = {
  characters: Game["characters"];
  onSelectCharacter: (characterId: string) => void;
  selectedCharacters: string[];
};

function CharacterSelection({
  characters,
  onSelectCharacter,
  selectedCharacters,
}: CharacterSelectionProps) {
  console.log({ characters, selectedCharacters });
  return (
    <div>
      <Typography level="h4" sx={{ mb: 2 }}>
        Select a Character
      </Typography>
      {Object.entries(characters).map(([characterId, character]) => (
        <Button
          key={characterId}
          onClick={() => onSelectCharacter(characterId)}
          disabled={selectedCharacters.includes(characterId)}
          sx={{ m: 1 }}
        >
          {character.name}
        </Button>
      ))}
    </div>
  );
}

export default CharacterSelection;
