import React, { useEffect, useState } from "react";
import LinearProgress from "@mui/joy/LinearProgress";

type SmoothProgressBarProps = {
  onComplete?: () => void;
  targetProgress: number;
};

const SmoothProgressBar: React.FC<SmoothProgressBarProps> = ({
  onComplete,
  targetProgress,
}) => {
  const [progress, setProgress] = useState(0);
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    const startValue = progress;
    const endValue = targetProgress;
    const duration = 1500; // Duration of animation in milliseconds
    const frameRate = 1000 / 60; // 60 FPS
    const totalFrames = Math.round(duration / frameRate);
    let frame = 0;

    const animate = () => {
      frame++;
      const newProgress =
        startValue + (endValue - startValue) * (frame / totalFrames);
      setProgress(newProgress);

      if (frame < totalFrames) {
        requestAnimationFrame(animate);
      } else {
        // Set isComplete to true once the animation is finished
        setIsComplete(newProgress >= 100);

        if (onComplete && newProgress === 100) {
          onComplete();
        }
      }
    };

    animate();
  }, [targetProgress]);

  // Do not render the progress bar if the animation is complete
  if (isComplete) return null;

  return <LinearProgress determinate variant="soft" value={progress} />;
};

export default SmoothProgressBar;
