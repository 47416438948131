import { useState, useEffect, useCallback } from "react";
import useAudioRecorder from "./use-audio-recorder";

const MAX_RECORDING_TIME = 10;

const useVoiceRecording = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(MAX_RECORDING_TIME);
  const { startRecording, stopRecording, transcribedText } = useAudioRecorder();

  const handleStartRecording = useCallback(() => {
    setIsRecording(true);
    setSecondsLeft(MAX_RECORDING_TIME);
    startRecording();
  }, [startRecording]);

  const handleStopRecording = useCallback(async () => {
    setIsRecording(false);
    setIsProcessing(true);
    await stopRecording();
    setSecondsLeft(MAX_RECORDING_TIME);
  }, [stopRecording]);

  const toggleRecording = useCallback(() => {
    if (isRecording) {
      handleStopRecording();
    } else {
      handleStartRecording();
    }
  }, [isRecording, handleStartRecording, handleStopRecording]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isRecording && secondsLeft > 0) {
      intervalId = setInterval(() => {
        setSecondsLeft((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    if (secondsLeft === 0) {
      handleStopRecording();
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [handleStopRecording, isRecording, secondsLeft]);

  useEffect(() => {
    if (transcribedText) {
      setIsProcessing(false);
    }
  }, [transcribedText]);

  return {
    isRecording,
    isProcessing,
    secondsLeft,
    transcribedText,
    toggleRecording,
    MAX_RECORDING_TIME,
  };
};

export default useVoiceRecording;
