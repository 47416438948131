import axios from "axios";
import { useParams } from "react-router-dom";
import React, { useState } from "react";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import {
  CharacterSelection,
  Conversation,
  DiceRoller,
  ImageGallery,
  Map,
  MessageForm,
  Objectives,
  PlayerAvatars,
} from ".";
import {
  useAuth,
  useGameState,
  usePlaythroughImages,
  usePlaythroughState,
} from "../../hooks";

import { SmoothProgressBar } from ".";

function Game() {
  const { slug, playthroughId } = useParams();
  const { user } = useAuth();
  const userId = user?.uid;
  const [localPlaythroughId, setLocalPlaythroughId] = useState<
    string | undefined
  >(playthroughId);
  const { gameState, isLoading, error } = useGameState(slug);
  console.log({ gameState });
  const { playthroughState } = usePlaythroughState(localPlaythroughId);
  const { images, isLoading: imagesLoading } = usePlaythroughImages(
    slug,
    localPlaythroughId
  );
  const [isProcessingMessage, setIsProcessingMessage] = useState(false);

  const handleSelectCharacter = async (characterId: string) => {
    if (!userId || !slug) return;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/games/select-character`,
        {
          userId,
          slug,
          characterId,
          playthroughId: localPlaythroughId,
        }
      );

      setLocalPlaythroughId(response.data.playthroughId);
      // Update URL with new playthroughId
      window.history.pushState(
        {},
        "",
        `/games/${slug}/${response.data.playthroughId}`
      );
    } catch (error) {
      console.error("Error selecting character:", error);
    }
  };

  const sendMessage = (text: string) => {
    if (text.trim()) {
      setIsProcessingMessage(true);
      axios
        .post(process.env.REACT_APP_SERVER_URL + "/chat", {
          message: text,
          playthroughId: localPlaythroughId,
          slug,
          userId,
        })
        .then((response) => {
          if (
            response.data.playthroughId &&
            response.data.playthroughId !== localPlaythroughId
          ) {
            const playthroughId = response.data.playthroughId;
            setLocalPlaythroughId(playthroughId);
            // Update URL with new playthroughId
            window.history.pushState(
              {},
              "",
              `/games/${slug}/${response.data.playthroughId}`
            );
          }
        })
        .catch((error) => {
          console.error("Error sending message:", error);
          setIsProcessingMessage(false);
        });
    }
  };
  if (isLoading || imagesLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading game state.</div>;
  if (!slug && !playthroughId)
    return <div>Invalid slug or playthrough ID.</div>;
  if (!userId) return <div>Invalid user ID.</div>;

  const player = playthroughState?.players[userId];
  const selectedCharacters = playthroughState
    ? Object.values(playthroughState.players).map((p) => p.characterId)
    : [];

  if (!gameState) return <div>Game not found.</div>;

  if (!player?.characterId) {
    return (
      <CharacterSelection
        characters={gameState.characters}
        onSelectCharacter={handleSelectCharacter}
        selectedCharacters={selectedCharacters}
      />
    );
  }

  return (
    <Stack
      spacing={1}
      sx={{
        height: "calc(100vh - 64px)", // Assuming 64px navbar height
        padding: 1,
        boxSizing: "border-box",
        top: "64px",
        position: "absolute",
      }}
    >
      <Typography level="h4" sx={{ textAlign: "center", flexShrink: 0 }}>
        {gameState.title}
      </Typography>

      <Stack direction="row" spacing={1} sx={{ flexGrow: 1, minHeight: 0 }}>
        <Sheet sx={{ width: "50%", display: "flex", flexDirection: "column" }}>
          <Typography level="h4" sx={{ p: 0.5, flexShrink: 0 }}>
            Conversation
          </Typography>
          <Sheet
            sx={{ flexGrow: 1, overflowY: "auto", padding: 1 }}
            variant="outlined"
          >
            <Conversation
              conversation={
                playthroughState?.players[userId].conversation ?? [
                  { role: "narrator", message: player.initialPrompt },
                ]
              }
              playerName={player?.name ?? ""}
            />
          </Sheet>
        </Sheet>
        <Sheet sx={{ width: "50%", display: "flex", flexDirection: "column" }}>
          {playthroughState && (
            <>
              <Objectives objectives={player.objectives} />
              <Typography level="h4" sx={{ p: 0.5, flexShrink: 0 }}>
                Map
              </Typography>
              <Sheet
                sx={{ flexGrow: 1, overflowY: "auto", padding: 1 }}
                variant="outlined"
              >
                <Map map={playthroughState?.map} player={player} />
              </Sheet>
            </>
          )}
          {player && player.diceRoll && (
            <DiceRoller rollValue={player.diceRoll} />
          )}
        </Sheet>
      </Stack>

      <Sheet sx={{ flexShrink: 0 }}>
        <SmoothProgressBar
          targetProgress={
            !isProcessingMessage
              ? 0
              : playthroughState?.messageProcessingProgress ?? 5
          }
          onComplete={() => setIsProcessingMessage(false)}
        />
        <MessageForm isDisabled={isProcessingMessage} onSend={sendMessage} />
      </Sheet>
    </Stack>
  );
}

export default Game;
